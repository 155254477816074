import { getContext, takeLatest, all, fork, call, takeEvery, race, take } from 'redux-saga/effects';
import * as Util from 'utils/saga/createPromise';

import {
  NEXT_PAPER_PAGE,
  SUBMIT_QUESTION_ANSWER,
  SUBMIT_QUESTION_PAPER,
  AUTO_SKIP_NEXT_PAPER_PAGE,
  CANCEL_PENDING,
  ROUTER_CHANGE,
} from './form.actions';

const SAGA_FOR_ROUTE = {
  '/': function* homeSaga() {},
  '/todos': function* listTodosSaga() {},
  '/todos/:id': function* showTodoSaga() {},
  '/todos/new': function* newTodoSaga() {},
  '/todos/:id/edit': function* editTodoSaga() {},
};

// Apollo 를 쓸땐
// payload에 callback을 실어서..
// ex) dispatch(getUser({callback: ME}))
const submitQuestionAnswerSaga = Util.createQuestionPromiseSaga(
  SUBMIT_QUESTION_ANSWER /*, myAPI.getUser*/
);

const submitQuestionPaperSaga = Util.createPromiseSaga(SUBMIT_QUESTION_PAPER /*, myAPI.getUser*/);

const skipPageToNextPage = Util.createPendingSaga(NEXT_PAPER_PAGE);

function* racePendingSaga(...args) {
  yield race({
    task: call(skipPageToNextPage, ...args),
    cancel: take(CANCEL_PENDING),
  });
}

function* navigationSaga(action) {
  const { gotoPage } = action.payload;
  const router = yield getContext('router');
  router.push(gotoPage);
}

function* watchSubmitQuestionAnswer() {
  yield takeEvery(SUBMIT_QUESTION_ANSWER, submitQuestionAnswerSaga);
}

function* watchSubmitQuestionPaper() {
  yield takeEvery(SUBMIT_QUESTION_PAPER, submitQuestionPaperSaga);
}

function* watchSkipPage() {
  yield takeLatest(AUTO_SKIP_NEXT_PAPER_PAGE, racePendingSaga);
}

function* watchRouteChange() {
  yield takeLatest(ROUTER_CHANGE, navigationSaga);
}

export default function* saga() {
  yield all([
    // fork(someSagaWatcher),
    fork(watchSubmitQuestionAnswer),
    fork(watchSubmitQuestionPaper),
    fork(watchSkipPage),
    fork(watchRouteChange),
  ]);
}
