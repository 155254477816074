// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://527820bf2a714d9698abe6541f007ed3@o451717.ingest.sentry.io/5998450',
  tracesSampleRate: 0.5,
  beforeSend: (event) => {
    if (process.env.NEXT_PUBLIC_NODE_ENV === 'development') {
      return null;
    }
    return event;
  },
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
