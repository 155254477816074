import { takeLatest, all, fork, call, getContext, delay } from 'redux-saga/effects';
import { createUrlWithQuery } from 'utils/createUrlWithQuery';

// import { fetchApplyEvents } from '../../api/pick';
import { SHOW_EVENT_DETAIL, APPLY_FORM, APPLY_FORM_END } from './applyForm.actions';
import { pixelEvent } from 'utils/pixel';

function* workerShowEventDetail(action) {
  const router = yield getContext('router');
  yield delay(300); // 폼 닫힘 애니메이션 300ms
  router.push(action.payload);
}

function* workerApplyForm(action) {
  const router = yield getContext('router');
  const { name, phone, eventIds, events } = action.payload;

  // FIXME:
  // try {
  //   const response = yield call(fetchApplyEvents({ name, phone, eventIds, events }));

  //   if (response.data.status) {
  //     pixelEvent('CompleteRegistration');
  //     console.log('submit success');
  //     // yield put(applyFormEnd());
  //     // yield delay(300);
  //     // history.push('/pick/all?filter=all');
  //   }
  // } catch (error) {
  //   console.error(error);
  //   // TODO: handle error
  // }
}

function* workerApplyFormEnd(action) {
  const router = yield getContext('router');
  const { query } = action.payload;
  yield delay(300); // 폼 닫힘 애니메이션 300ms
  pixelEvent('CompleteRegistration');
  router.push(createUrlWithQuery({ query, url: '/confirm' }));
}

function* watchShowEventDetail() {
  yield takeLatest(SHOW_EVENT_DETAIL, workerShowEventDetail);
}

function* watchApplyForm() {
  yield takeLatest(APPLY_FORM, workerApplyForm);
}

function* watchApplyFormEnd() {
  yield takeLatest(APPLY_FORM_END, workerApplyFormEnd);
}

export default function* applyFormSaga() {
  yield all([fork(watchShowEventDetail), fork(watchApplyForm), fork(watchApplyFormEnd)]);
}
