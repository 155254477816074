export const createUrlWithQuery = ({ query, url }) => {
  try {
    if (query) {
      const newQuery = Object.keys(query).reduce((acc, key) => {
        if (key === 'eventId' || key === 'packageId' || key === 'categoryId') {
          return acc;
        }

        acc[key] = query[key];
        return acc;
      }, {});

      return Object.keys(newQuery).reduce((acc, key, idx) => {
        if (idx === 0) {
          acc += `?${key}=${query[key]}`;
        } else {
          acc += `&${key}=${query[key]}`;
        }

        return acc;
      }, url);
    } else {
      return url;
    }
  } catch (error) {
    console.error('parse query', error);
    return url;
  }
};
