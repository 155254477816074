import styled from 'styled-components';

import Spin from 'components/atoms/Spin';

export default function PageLoader() {
  return (
    <Loader>
      <Spin />
    </Loader>
  );
}

const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background-color: rgba(55, 55, 55, 0.5);
  z-index: 99;
  justify-content: center;
  align-items: center;
`;
