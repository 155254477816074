import { createReducer } from 'typesafe-actions';

import {
  GET_PACKAGE_REQUEST,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_FAILURE,
  SELECT_EVENT,
} from './package.actions';

const initialState = {};

const packageReducer = createReducer(initialState, {
  [GET_PACKAGE_REQUEST]: (state) => ({ ...state }),
  [GET_PACKAGE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.id]: {
      ...action.payload,
      events: action.payload.events.map((event) => ({ ...event, selected: false })),
    },
  }),
  [GET_PACKAGE_FAILURE]: (state) => ({ ...state }),

  [SELECT_EVENT]: (state, action) => {
    const { packageId, eventId } = action.payload;

    return {
      ...state,
      [packageId]: {
        ...state[packageId],
        events: state[packageId].events.map((event) => {
          if (event.id === eventId) {
            return { ...event, selected: !event.selected };
          }
          return event;
        }),
      },
    };
  },
});

export default packageReducer;
