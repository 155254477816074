import styled from 'styled-components';
import Link from 'next/link';

import ApplyPhilingCard from 'components/molecules/PhilingMyCard/ApplyPhilingCard';
import { PC_MENU } from './menuData';

export default function LayoutLeftTemplate() {
  return (
    <Container>
      <Link href="/philing">
        <Logo src="https://images.odoctor.co.kr/philing/card/pt-logo.png" alt="오닥터" />
      </Link>
      <Desc>동안이 되기 위한 노하우부터</Desc>
      <Desc>전문 큐레이션 서비스까지</Desc>
      <Desc bold style={{ marginTop: '0.5rem' }}>{`‘젊음’을 찾아주는 올인원 플랫폼`}</Desc>
      <Wrap>
        {PC_MENU.map((row, idx) => (
          <Row key={idx}>
            {row.map(({ label, link }) => (
              <Link key={link} href={''}>
                <Tag>{label}</Tag>
              </Link>
            ))}
          </Row>
        ))}
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  padding-left: 5rem;
`;

const Logo = styled.img`
  width: 25rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Desc = styled.p`
  font-size: 3rem;
  font-weight: ${({ bold }) => bold && 'bold'};
`;

const Wrap = styled.div`
  margin: 4rem 0;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const Tag = styled.div`
  font-size: 2rem;
  background-color: #e1ea68;
  border-radius: 2rem;
  padding: 1rem 2rem;
  margin-right: 1rem;
  cursor: pointer;
  &:active {
    transform: translateY(0.3rem);
  }
`;
