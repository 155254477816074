import { createReducer } from 'typesafe-actions';

import { SET_REVIEW_COUNT } from './review.actions';

const initialState = {
  reviewCount: 0,
};

const reviewReducer = createReducer(initialState, {
  [SET_REVIEW_COUNT]: (state, action) => ({
    reviewCount: action.payload,
  }),
});

export default reviewReducer;
