import { createAction } from 'typesafe-actions';

export const SET_AUTH = 'SET_AUTH';
export const setAuth = createAction(SET_AUTH)();

export const UPDATE_AUTH = 'UPDATE_AUTH';
export const updateAuth = createAction(UPDATE_AUTH)();

export const RESET_AUTH = 'RESET_AUTH';
export const resetAuth = createAction(RESET_AUTH)();
