import { createReducer } from 'typesafe-actions';

import { RESET_PHILING, SET_PHILING } from './philing.actions';

const initialState = {
  receiveMethod: 'delivery',
  deliveryMsg: '',
  addressId: '',
};

const philingReducer = createReducer(initialState, {
  [SET_PHILING]: (state, action) => {
    return { ...action.payload };
  },

  [RESET_PHILING]: () => initialState,
});

export default philingReducer;
