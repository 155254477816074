export const PC_MENU = [
  [
    {
      label: '동안비결',
      link: '/community/cat/1',
    },
    {
      label: '안티에이징',
      link: '/community/cat/3',
    },
  ],
  [
    // {
    //   label: '성기능',
    //   link: '/philing/3',
    // },
    {
      label: '항노화',
      link: '/community/cat/7',
    },
    {
      label: '바이오해킹',
      link: '/community/cat/4',
    },
  ],
  [
    {
      label: '동안피부',
      link: '/community/cat/2',
    },
    {
      label: '시술',
      link: '/community/cat/10',
    },
  ],
];
