/**
 * 픽셀 코드
 * Lead 잠재고객 획득 - 상담 신청폼 열때
 * compeleteRegistration 등록 완료 - 상담 신청 완료시
 *
 *
 * ** 주의 사항 **
 * GTM 에서 픽셀 코드 삽입 함...
 *  코드내에는 픽셀코드 설치가 없으니 유의
 */
export const pixelEvent = (eventName, options) => {
  if (window?.fbq) {
    window.fbq('track', eventName, options);
  } else {
    console.log('odoctor.co.kr 외에는 수집하지 않습니다.');
  }
};

export const pixelInit = (id) => {
  if (window?.fbq) {
    window.fbq('init', id);
  } else {
    console.log('odoctor.co.kr 외에는 수집하지 않습니다.');
  }
};
