import Router from 'next/router';
import { applyMiddleware, compose, createStore } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import { createRouterMiddleware, initialRouterState } from 'connected-next-router';
import createSagaMiddleware from 'redux-saga';

import reducer from 'modules/rootReducer';
import { rootSaga } from 'modules/rootSaga';

const initStore = (context) => {
  const routerMiddleware = createRouterMiddleware();

  const sagaMiddleware = createSagaMiddleware({
    context: { router: Router },
  });

  const middlewares = [routerMiddleware, sagaMiddleware];

  const { asPath } = context.ctx || Router.router || {};

  let initialState;
  const composeEnhancer =
    (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  if (asPath) {
    initialState = {
      router: initialRouterState(asPath),
    };
  }

  const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...middlewares))
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const storeWrapper = createWrapper(initStore);
