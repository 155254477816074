import { call, put, delay } from 'redux-saga/effects';

// 프로미스를 기다렸다가 결과를 디스패치하는 사가
export const createPromiseSaga = (type, promiseCreator) => {
  const [SUCCESS, ERROR, FINAL] = [`${type}_SUCCESS`, `${type}_ERROR`, `${type}_FINAL`];
  return function* saga(action) {
    try {
      const payload = yield call(action.payload.callback || promiseCreator, action.payload.data);
      // input data까지 넘김
      yield put({ type: SUCCESS, payload: { ...payload.data, ...action.payload } });

      // yield put({
      //   type: NEXT_PAPER_PAGE,
      //   payload: {
      //     key: 'PAPER_FORM',
      //     page: 'almost-done',
      //   },
      // });

      // 마지막 문진 로직 변경
      // (로그인 확인 후, almost-done 페이지 이동)에서..
      // /my/medi/[treatmentId] 로 이동..
      const { treatmentId } = action.payload;
      yield put({ type: FINAL });
      yield put({
        type: 'ROUTER_CHANGE',
        payload: { gotoPage: `/my/medi/${treatmentId}` },
      });
    } catch (e) {
      yield put({ type: ERROR, error: true, payload: e });
    }
  };
};

// 프로미스를 기다렸다가 결과를 디스패치하는 사가
export const createQuestionPromiseSaga = (type, promiseCreator) => {
  const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];
  return function* saga(action) {
    try {
      const payload = yield call(
        action.payload.callback || promiseCreator,
        action.payload.answer || action.payload
      );
      // input data까지 넘김
      yield put({ type: SUCCESS, payload: { ...payload.data, ...action.payload } });
      const nextQuestion = payload.data.submitQuestionAnswer.nextQuestion;
      // nextQuestion?
      if (nextQuestion) {
        yield put({
          type: 'NEXT_PAPER_PAGE',
          payload: { ...action.payload, page: `question-${nextQuestion.order}` },
        });
        yield put({
          type: 'EDIT_QUESTION_PAPER',
          payload: {
            ...action.payload,
            question: nextQuestion,
          },
        });
      } else {
        // 마지막 Question 이므로
        // 로그인 확인 후 submitPaper 후
        // paperId를 저장하고 NextPage 로 이동한다
        yield put({
          type: 'SET_POPUP',
          payload: {
            page: 'login',
            value: true,
          },
        });
      }
    } catch (e) {
      console.log(e);
      yield put({ type: ERROR, error: true, payload: e });
    }
  };
};

export const createPendingSaga = (type, ms = 3000) => {
  return function* saga(action) {
    yield delay(action.payload.ms || ms);
    yield put({
      type: type,
      payload: action.payload,
    });
  };
};
