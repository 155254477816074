import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

export default function ApplyPhilingCard() {
  return (
    <Link href="/philing">
      <Container>
        <Label>philing</Label>
        <Row>처방 받은 모든 약 무료배송 🚚</Row>
        <Button>비대면 진료 받기</Button>
      </Container>
    </Link>
  );
}

const Container = styled.div`
  position: relative;
  background-color: #dccbe5;
  padding: 2.5rem;
  border-radius: 2rem;
  cursor: pointer;

  &:active {
    transform: translateY(0.3rem);
  }
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
`;

const Button = styled.button`
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 1rem 0;
  border-radius: 2rem;
  img {
    width: 3rem;
  }
`;
