import axios from 'axios';

import { getCookie } from 'utils/cookie';

class Http {
  baseURL;
  instance;

  constructor() {
    this.baseURL = process.env.NEXT_PUBLIC_API_URL || '';
    this.instance = axios.create({ baseURL: this.baseURL, timeout: 10000 });

    this.initialize();
  }

  initialize() {
    this.instance.interceptors.request.use((config) => {
      const token = getCookie({ name: 'token' });
      if (token) {
        config.headers['authorization'] = `Bearer ${token}`;
      }

      return config;
    });

    this.instance.interceptors.response.use(undefined, (error) => {
      const { status } = error.response;
      if (status === 401) {
        console.log('미네랄이 부족합니다.');
      }
    });
  }
}

export default new Http();
