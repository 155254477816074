import { createAction, createAsyncAction } from 'typesafe-actions';

export const GET_POST_REQUEST = 'GET_POST_REQUEST';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_FAILURE = 'GET_POST_FAILURE';
export const actionGetPost = createAsyncAction(
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POST_FAILURE
)();

export const FETCH_MORE_REPLY_REQUEST = 'FETCH_MORE_REPLY_REQUEST';
export const FETCH_MORE_REPLY_SUCCESS = 'FETCH_MORE_REPLY_SUCCESS';
export const FETCH_MORE_REPLY_FAILURE = 'FETCH_MORE_REPLY_FAILURE';
export const actionFetchMoreReply = createAsyncAction(
  FETCH_MORE_REPLY_REQUEST,
  FETCH_MORE_REPLY_SUCCESS,
  FETCH_MORE_REPLY_FAILURE
)();

export const FETCH_MORE_NESTED_REPLY_REQUEST = 'FETCH_MORE_NESTED_REPLY_REQUEST';
export const FETCH_MORE_NESTED_REPLY_SUCCESS = 'FETCH_MORE_NESTED_REPLY_SUCCESS';
export const FETCH_MORE_NESTED_REPLY_FAILURE = 'FETCH_MORE_NESTED_REPLY_FAILURE';
export const actionFetchMoreNestedReply = createAsyncAction(
  FETCH_MORE_NESTED_REPLY_REQUEST,
  FETCH_MORE_NESTED_REPLY_SUCCESS,
  FETCH_MORE_NESTED_REPLY_FAILURE
)();

export const TOGGLE_POST_LIKE_REQUEST = 'TOGGLE_POST_LIKE_REQUEST';
export const TOGGLE_POST_LIKE_SUCCESS = 'TOGGLE_POST_LIKE_SUCCESS';
export const TOGGLE_POST_LIKE_FAILURE = 'TOGGLE_POST_LIKE_FAILURE';
export const actionTogglePostLike = createAsyncAction(
  TOGGLE_POST_LIKE_REQUEST,
  TOGGLE_POST_LIKE_SUCCESS,
  TOGGLE_POST_LIKE_FAILURE
)();

export const TOGGLE_LIKE_BY_REPLY_ID_REQUEST = 'TOGGLE_LIKE_BY_REPLY_ID_REQUEST';
export const TOGGLE_LIKE_BY_REPLY_ID_SUCCESS = 'TOGGLE_LIKE_BY_REPLY_ID_SUCCESS';
export const TOGGLE_LIKE_BY_REPLY_ID_FAILURE = 'TOGGLE_LIKE_BY_REPLY_ID_FAILURE';
export const actionToggleLikeByReplyId = createAsyncAction(
  TOGGLE_LIKE_BY_REPLY_ID_REQUEST,
  TOGGLE_LIKE_BY_REPLY_ID_SUCCESS,
  TOGGLE_LIKE_BY_REPLY_ID_FAILURE
)();

export const CREATE_REPLY_REQUEST = 'CREATE_REPLY_REQUEST';
export const CREATE_REPLY_SUCCESS = 'CREATE_REPLY_SUCCESS';
export const CREATE_REPLY_FAILURE = 'CREATE_REPLY_FAILURE';
export const actionCreateReply = createAsyncAction(
  CREATE_REPLY_REQUEST,
  CREATE_REPLY_SUCCESS,
  CREATE_REPLY_FAILURE
)();

export const SET_SORT = 'SET_SORT';
export const actionSetSort = createAction(SET_SORT)();
