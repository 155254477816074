import { createReducer } from 'typesafe-actions';

import { SET_AUTH, UPDATE_AUTH, RESET_AUTH } from './auth.actions';

const initialState = {
  isLogin: false,
  user: {},
};

const authReducer = createReducer(initialState, {
  [SET_AUTH]: (state, action) => ({
    isLogin: true,
    user: action.payload,
  }),

  [UPDATE_AUTH]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      [action.payload.key]: action.payload.value,
    },
  }),

  [RESET_AUTH]: () => initialState,
});

export default authReducer;
