import { createAsyncAction, createAction } from 'typesafe-actions';

export const GET_PACKAGE_REQUEST = 'GET_PACKAGE_REQUEST';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_FAILURE = 'GET_PACKAGE_FAILURE';

export const SELECT_EVENT = 'SELECT_EVENT';

export const getPackage = createAsyncAction(
  GET_PACKAGE_REQUEST,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_FAILURE
)();

export const selectEvent = createAction(SELECT_EVENT)();
