import { useMemo } from 'react';
import { ApolloClient, HttpLink, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import shuffle from 'lodash/shuffle';
import isEqual from 'lodash/isEqual';
import { serialize } from 'cookie';

import { getCookie } from 'utils/cookie';

// const httpLink = new HttpLink({
//   uri: process.env.NEXT_PUBLIC_API_URL,
//   headers: {
//     authorization: token ? `Bearer ${token}` : '',
//   },
// });

const authLink = (token) => {
  return setContext((_, { headers, ...context }) => {
    const IS_SERVER = typeof window === 'undefined';

    if (IS_SERVER) {
      // Access localStorage
      return {
        headers: {
          ...headers,
          ...(token ? { authorization: `Bearer ${token}` } : {}),
        },
        ...context,
      };
    }

    // todo : rebuild
    // shallow route 중 토큰을 받았을때,
    // ssr 모드에서 token을 갱신 받지 못하는 문제
    if (!token) {
      const ctoken = getCookie({ name: 'token' });
      return {
        headers: {
          ...headers,
          ...(ctoken ? { authorization: `Bearer ${ctoken}` } : {}),
        },
        ...context,
      };
    }
  });
};

// const authMiddleware = new ApolloLink((operation, forward) => {

//   return {
//     headers: {
//       ...headers,
//       ...(token ? { authorization: `Bearer ${token}` } : {}),
//     },
//     ...context,
//   };

//   operation.setContext(({ headers = {} }) => {
//     return {
//       headers: {
//         authorization: token ? `Bearer ${token}` : '',
//         ...headers,
//       },
//     };
//   });
//   return forward(operation);
// });

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();
    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');

      if (token) {
        localStorage.setItem('token', token);
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
    }

    return response;
  })
);

const errorHandleLink = (res) => {
  return onError(({ response, graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // console.error(message);

        if (message === 'Not authenticated') {
          // alert('로그인이 필요한 기능입니다.');
          // document.location.href = '/';
        }

        if (message === 'NotFoundError') {
          // alert('서버에 문제가 있습니다.');
        }

        if (message === 'invalid token') {
          if (typeof window === 'undefined') {
            res.statusCode = 404;
            res.setHeader('Set-Cookie', [
              serialize('token', '', { path: '/', expires: new Date(0) /* httpOnly: true */ }),
            ]);
            res.setHeader('Location', '/');
            response.errors = null;
          }
        }

        // 토큰변조
        if (message === 'invalid signature' || message.indexOf('Unexpected token') !== -1) {
          res.statusCode = 400;
          res.setHeader('Set-Cookie', [
            serialize('token', '', { path: '/', expires: new Date(0) /* httpOnly: true */ }),
          ]);
          res.setHeader('Location', '/login');
          response.errors = null;
        }

        // 토큰 없을때
        // if (message === 'Token Not Provided') {
        //   if (typeof window === 'undefined') {
        //     res.statusCode = 302;
        //     res.setHeader('Set-Cookie', [
        //       serialize('token', '', { path: '/', expires: new Date(0) /* httpOnly: true */ }),
        //     ]);
        //     res.setHeader('Location', '/login');
        //     response.errors = null;
        //   }
        // }
      });
    }

    if (networkError && networkError.statusCode === 401) {
      document.location.href = '/';
    }
  });
};

const shuffleEvents = new ApolloLink((operation, forward) => {
  if (operation.operationName === 'GetEventsWithHospitalCategory') {
    return forward(operation).map((response) => {
      return {
        data: {
          ...response.data,
          events: {
            ...response.data.events,
            data: shuffle(response.data.events.data),
          },
        },
      };
    });
  }

  if (operation.operationName === 'GetEventPackagesWithHospitalCategory') {
    return forward(operation).map((response) => {
      return {
        data: {
          ...response.data,
          eventPackages: {
            ...response.data.eventPackages,
            data: shuffle(response.data.eventPackages.data),
          },
        },
      };
    });
  }

  return forward(operation);
});

function createApolloClient(res, token) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: from([
      authLink(token),
      afterwareLink,
      // errorLink,
      errorHandleLink(res),
      shuffleEvents,
      new HttpLink({
        uri: process.env.NEXT_PUBLIC_API_URL,
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      }),
    ]),
    cache: new InMemoryCache(),
  });
}

let apolloClient;
export function initializeApollo(initialState = null, token = null, res) {
  if (typeof document !== 'undefined') {
    // console.log(document);
  }

  const _apolloClient =
    apolloClient ?? createApolloClient(res, token || getCookie({ name: 'token' }));

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
