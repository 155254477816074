import createReducer from 'utils/immerWrapper';

import { SET_QUIZ, RESET_QUIZ } from './quiz.actions';

export const initialFormsState = { ongoing: {} };

const formReducer = createReducer(initialFormsState, {
  // [SET_QUIZ]: (draft, action) => ({
  //   isLogin: true,
  //   user: action.payload,
  // }),
  [SET_QUIZ]: (draft, action) => {
    const { key, state } = action.payload;
    draft[key] = state;
  },
  [RESET_QUIZ]: (state, action) => initialState,
});

export default formReducer;
``;
