import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import * as ACTIONS from './community.actions';

function getLikeCount(myLike, likeCount) {
  return myLike ? likeCount - 1 : likeCount + 1;
}

const initialState = {
  isFetching: false,
  sort: 'latest',
  post: {
    category: { id: 0, title: '' },
    commentCount: 0,
    comments: {
      data: [],
      pagination: {
        currentPage: 1,
        lastPage: 1,
        perPage: 3,
        total: 1,
      },
    },
    content: '',
    createdAt: '',
    id: 0,
    images: [],
    likeCount: 0,
    myLike: false,
    title: '',
    updatedAt: '',
    user: { id: '', email: '', nickname: '' },
  },
};

const communityReducer = createReducer(initialState, {
  [ACTIONS.GET_POST_REQUEST]: (state) => ({ ...state, isFetching: true }),
  [ACTIONS.GET_POST_FAILURE]: (state) => ({ ...state, isFetching: false }),
  [ACTIONS.GET_POST_SUCCESS]: (state, action) => {
    return { ...state, isFetching: false, post: action.payload };
  },

  [ACTIONS.FETCH_MORE_REPLY_REQUEST]: (state) => ({ ...state, isFetching: true }),
  [ACTIONS.FETCH_MORE_REPLY_FAILURE]: (state) => ({ ...state, isFetching: false }),
  [ACTIONS.FETCH_MORE_REPLY_SUCCESS]: (state, action) => {
    const { data, pagination } = action.payload.comments;
    return produce(state, (draft) => {
      draft.isFetching = false;
      draft.post.comments.data.push(...data);
      draft.post.comments.pagination = pagination;
    });
  },

  [ACTIONS.FETCH_MORE_NESTED_REPLY_REQUEST]: (state) => ({ ...state, isFetching: true }),
  [ACTIONS.FETCH_MORE_NESTED_REPLY_FAILURE]: (state) => ({ ...state, isFetching: false }),
  [ACTIONS.FETCH_MORE_NESTED_REPLY_SUCCESS]: (state, action) => {
    const { isFirst, parentId, response } = action.payload;
    return produce(state, (draft) => {
      draft.isFetching = false;
      const reply = draft.post.comments.data.find(({ id }) => id === parentId);
      if (isFirst) {
        reply.nestedComments.data = response.data;
      } else {
        reply.nestedComments.data.push(...response.data);
      }
      reply.nestedComments.pagination = response.pagination;
    });
  },

  [ACTIONS.TOGGLE_POST_LIKE_REQUEST]: (state) => ({ ...state, isFetching: true }),
  [ACTIONS.TOGGLE_POST_LIKE_FAILURE]: (state) => ({ ...state, isFetching: false }),
  [ACTIONS.TOGGLE_POST_LIKE_SUCCESS]: (state) => {
    return {
      ...state,
      isFetching: false,
      post: {
        ...state.post,
        likeCount: getLikeCount(state.post.myLike, state.post.likeCount),
        myLike: !state.post.myLike,
      },
    };
  },

  [ACTIONS.TOGGLE_LIKE_BY_REPLY_ID_REQUEST]: (state) => ({ ...state, isFetching: true }),
  [ACTIONS.TOGGLE_LIKE_BY_REPLY_ID_FAILURE]: (state) => ({ ...state, isFetching: false }),
  [ACTIONS.TOGGLE_LIKE_BY_REPLY_ID_SUCCESS]: (state, action) => {
    const { id, parentId } = action.payload;
    return produce(state, (draft) => {
      if (parentId) {
        const reply = draft.post.comments.data.find((reply) => reply.id === parentId);
        const nestedReply = reply.nestedComments.data.find((nestedReply) => nestedReply.id === id);

        nestedReply.likeCount = getLikeCount(nestedReply.myLike, nestedReply.likeCount);
        nestedReply.myLike = !nestedReply.myLike;
      } else {
        const reply = draft.post.comments.data.find((reply) => reply.id === id);

        reply.likeCount = getLikeCount(reply.myLike, reply.likeCount);
        reply.myLike = !reply.myLike;
      }

      draft.isFetching = false;
    });
  },

  [ACTIONS.CREATE_REPLY_REQUEST]: (state) => ({ ...state, isFetching: true }),
  [ACTIONS.CREATE_REPLY_FAILURE]: (state) => ({ ...state, isFetching: false }),
  [ACTIONS.CREATE_REPLY_SUCCESS]: (state, action) => {
    // FIXME: 내가 새로 작성한 댓글이나 대댓글이 어디에 나와야하지? (최신순, 따봉순일때)
    const { parent: parentId } = action.payload;
    return produce(state, (draft) => {
      if (!parentId) {
        draft.post.comments.data.push(action.payload);
      } else {
        const reply = draft.post.comments.data.find(({ id }) => id === parentId);
        reply.nestedComments.data.push(action.payload);
      }
    });
  },

  [ACTIONS.SET_SORT]: (state, action) => ({
    ...state,
    sort: action.payload,
  }),
});

export default communityReducer;
