/**
 *
 * @param name
 * @param value
 * @param days
 * @description has 3 days as default
 */
export const setCookie = ({ name, value, days = 3 }) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}${expires}; path=/`;
};

/**
 *
 * @param name
 * @returns {string}
 */
export const getCookie = ({ name }) => {
  let i;
  let x;
  let y;

  if (typeof document !== 'undefined') {
    const ARRcookies = document.cookie.split(';');

    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');

      if (x === name) {
        return unescape(y);
      }
    }
  } else {
    return null;
  }
};

export const deleteCookie = ({ name }, c) => {
  if (getCookie({ name })) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    c();
  }
};
