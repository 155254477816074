import { createReducer } from 'typesafe-actions';

import { GET_EVENT_REQUEST, GET_EVENT_SUCCESS, GET_EVENT_FAILURE } from './event.actions';

const initialState = {
  applyCount: 0,
  category: null,
  contentPageUrl: null,
  description: '',
  discountRate: 0,
  discountedPrice: 0,
  endDate: null,
  hospital: {
    id: '',
    name: '',
    location: {
      id: '',
      siName: '',
      gunguName: '',
    },
  },
  id: '',
  metatags: null,
  name: '',
  originalPrice: 0,
  startDate: null,
  therapyName: '',
  thumbnailUrl: '',
  wideThumbnailUrl: null,
};

const eventReducer = createReducer(initialState, {
  [GET_EVENT_REQUEST]: (state) => state,
  [GET_EVENT_SUCCESS]: (state, action) => ({ ...state, ...action.payload }),
  [GET_EVENT_FAILURE]: (state) => state,
});

export default eventReducer;
