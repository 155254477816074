import { createReducer } from 'typesafe-actions';

import {
  SET_SHOW_FORM,
  SET_SHOW_SINGLE_FORM,
  SET_FORM_FIELD,
  SET_DEFAULT_FORM,
  RESET_FORM,
  SHOW_EVENT_DETAIL,
  APPLY_FORM,
  APPLY_FORM_END,
} from './applyForm.actions';

const initialState = {
  showForm: false,
  showSingleForm: false,
  isFetching: false, // gql
  isFetchingExcel: false, // 엑셀
  name: '',
  phone: '',
  agreeTerm: true,
  ageGroup: '',
  contactTime: 'ANYTIME',
};

const applyFormReducer = createReducer(initialState, {
  [SET_SHOW_FORM]: (state, action) => {
    if (!action.payload) {
      return { ...state, showForm: action.payload, agreeTerm: true };
    }
    return { ...state, showForm: action.payload };
  },

  [SET_SHOW_SINGLE_FORM]: (state, action) => {
    if (!action.payload) {
      return { ...state, showSingleForm: action.payload, agreeTerm: true };
    }
    return { ...state, showSingleForm: action.payload };
  },

  [SET_FORM_FIELD]: (state, action) => ({ ...state, [action.payload.key]: action.payload.value }),

  [SET_DEFAULT_FORM]: (state, action) => ({
    ...state,
    name: action.payload.name || '',
    phone: action.payload.phone || '',
    ageGroup: action.payload.ageGroup || '',
    contactTime: 'ANYTIME',
  }),

  [SHOW_EVENT_DETAIL]: (state) => ({ ...state, showForm: false }),

  [APPLY_FORM]: (state) => ({ ...state, isFetching: true, isFetchingExcel: true }),

  [APPLY_FORM_END]: (state) => ({
    ...state,
    isFetching: false,
    isFetchingExcel: false,
    showForm: false,
  }),

  [RESET_FORM]: () => initialState,
});

export default applyFormReducer;
