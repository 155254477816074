import { takeLatest, select, all, fork, put } from 'redux-saga/effects';
import { print } from 'graphql';

import Http from 'api/Http';
import {
  CreatePostComment,
  GetNestedReply,
  GetPost,
  TogglePostCommentLike,
  TogglePostLike,
} from 'graphql/community';
import {
  actionCreateReply,
  actionFetchMoreNestedReply,
  actionFetchMoreReply,
  actionGetPost,
  actionToggleLikeByReplyId,
  actionTogglePostLike,
} from './community.actions';

function* workerActionGetPost(action) {
  try {
    const response = yield Http.instance.post('', {
      query: print(GetPost),
      variables: { id: Number(action.payload), pagination: { perPage: 20 } },
    });

    if (response.status === 200) {
      yield put(actionGetPost.success(response.data.data.post));
    } else {
      yield put(actionGetPost.failure());
    }
  } catch (error) {
    yield put(actionGetPost.failure(error));
  }
}

function* workerActionFetchMoreReply(action) {
  try {
    const { pagination } = yield select((state) => state.community.post.comments);
    const response = yield Http.instance.post('', {
      query: print(GetPost),
      variables: {
        id: action.payload,
        pagination: {
          currentPage: pagination.currentPage + 1,
          perPage: 10,
        },
      },
    });

    if (response.status === 200) {
      yield put(actionFetchMoreReply.success(response.data.data.post));
    } else {
      yield put(actionFetchMoreReply.failure());
    }
  } catch (error) {
    console.error(error);
  }
}

function* workerActionFetchMoreNestedReply(action) {
  const { parentId, nestedPagination } = action.payload;
  const { perPage, currentPage } = nestedPagination;
  let pagination = null;
  const isFirst = perPage === 3 && currentPage === 1;
  if (isFirst) {
    pagination = { currentPage: 1, perPage: 10 };
  } else {
    pagination = { currentPage: currentPage + 1, perPage: 10 };
  }

  try {
    const response = yield Http.instance.post('', {
      query: print(GetNestedReply),
      variables: { parentId, pagination },
    });

    if (response.status === 200) {
      yield put(
        actionFetchMoreNestedReply.success({
          isFirst,
          parentId,
          response: response.data.data.postNestedComments,
        })
      );
    } else {
      yield put(actionFetchMoreNestedReply.failure());
    }
  } catch (error) {
    console.error(error);
  }
}

function* workerActionTogglePostLike(action) {
  try {
    const response = yield Http.instance.post('', {
      query: print(TogglePostLike),
      variables: { postId: action.payload },
    });

    if (response.status === 200) {
      yield put(actionTogglePostLike.success());
    } else {
      yield put(actionTogglePostLike.failure());
    }
  } catch (error) {
    console.error(error);
  }
}

function* workerActionToggleLikeByReplyId(action) {
  const { id } = action.payload;
  try {
    const response = yield Http.instance.post('', {
      query: print(TogglePostCommentLike),
      variables: { commentId: id },
    });

    if (response.status === 200) {
      yield put(actionToggleLikeByReplyId.success(action.payload));
    } else {
      yield put(actionToggleLikeByReplyId.failure());
    }
  } catch (error) {
    console.error(error);
  }
}

function* workerActionCreateReply(action) {
  try {
    const response = yield Http.instance.post('', {
      query: print(CreatePostComment),
      variables: { input: action.payload },
    });

    if (response.status === 200) {
      yield put(actionCreateReply.success(response.data.data.createPostComment));
    } else {
      yield put(actionCreateReply.failure());
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchActionGetPost() {
  yield takeLatest(actionGetPost.request, workerActionGetPost);
}

function* watchActionTogglePostLike() {
  yield takeLatest(actionTogglePostLike.request, workerActionTogglePostLike);
}

function* watchActionToggleLikeByReplyId() {
  yield takeLatest(actionToggleLikeByReplyId.request, workerActionToggleLikeByReplyId);
}

function* watchActionFetchMoreReply() {
  yield takeLatest(actionFetchMoreReply.request, workerActionFetchMoreReply);
}

function* watchActionFetchMoreNestedReply() {
  yield takeLatest(actionFetchMoreNestedReply.request, workerActionFetchMoreNestedReply);
}

function* watchActionCreateReply() {
  yield takeLatest(actionCreateReply.request, workerActionCreateReply);
}

export default function* communitySaga() {
  yield all([
    fork(watchActionGetPost),
    fork(watchActionTogglePostLike),
    fork(watchActionToggleLikeByReplyId),
    fork(watchActionFetchMoreReply),
    fork(watchActionFetchMoreNestedReply),
    fork(watchActionCreateReply),
  ]);
}
