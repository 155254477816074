import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { routerReducer } from 'connected-next-router';

import packageReducer from './package/package.reducer';
import applyFormReducer from './applyForm/applyForm.reducer';
import authReducer from './auth/auth.reducer';
import formReducer from './form/form.reducer';
import quizReducer from './quiz/quiz.reducer';
import eventReducer from './event/event.reducer';
import reviewReducer from './review/review.reducer';
import philingReducer from './philing/philing.reducers';
import communityReducer from './community/community.reducer';

const rootReducer = combineReducers({
  router: routerReducer,
  applyForm: applyFormReducer,
  auth: authReducer,
  form: formReducer,
  quiz: quizReducer,
  event: eventReducer,
  package: packageReducer,
  review: reviewReducer,
  philing: philingReducer,
  community: communityReducer,
});

const reducer = (state, action) => {
  // 서버에서 생성한 store 주입
  if (action.type === HYDRATE) {
    const nextState = { ...state, ...action.payload };
    if (typeof window !== 'undefined' && state?.router) {
      // preserve router value on client side navigation
      nextState.router = state.router;
      nextState.form = state.form;
      nextState.quiz = state.quiz;
      nextState.philing = state.philing;
    }

    const nextStatePackageIds = Object.keys(nextState.package);
    const hasPackage = nextStatePackageIds.every((packageId) => state.package[packageId]);
    if (hasPackage) {
      nextState.package = state.package;
    }

    return nextState;
  }

  return rootReducer(state, action);
};

export default reducer;
