import { createAction } from 'typesafe-actions';

export const SET_SHOW_FORM = 'SET_SHOW_FORM';
export const setShowForm = createAction(SET_SHOW_FORM)();

export const SET_SHOW_SINGLE_FORM = 'SET_SHOW_SINGLE_FORM';
export const setShowSingleForm = createAction(SET_SHOW_SINGLE_FORM)();

export const SET_FORM_FIELD = 'SET_FORM_FIELD';
export const setFormField = createAction(SET_FORM_FIELD)();

export const RESET_FORM = 'RESET_FORM';
export const resetForm = createAction(RESET_FORM)();

export const SET_DEFAULT_FORM = 'SET_DEFAULT_FORM';
export const setDefaultForm = createAction(SET_DEFAULT_FORM)();

export const SHOW_EVENT_DETAIL = 'SHOW_EVENT_DETAIL';
export const showEventDetail = createAction(SHOW_EVENT_DETAIL)();

export const APPLY_FORM = 'APPLY_FORM';
export const applyForm = createAction(APPLY_FORM)();

export const APPLY_FORM_END = 'APPLY_FORM_END';
export const applyFormEnd = createAction(APPLY_FORM_END)();
