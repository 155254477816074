import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform rotate(0deg)
  }
  50% {
    transform rotate(180deg)
    opacity .35
  }
  100% {
    transform rotate(360deg)
  }
`;

export const Spin = styled.span`
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 100%;
  border-top: 3px solid transparent;
  margin-right: 1rem;
  animation: ${spin} infinite linear 1s;
`;
